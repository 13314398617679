<template>
  <div class="page">
    <div class="page-header">
      <a-form-model
        class="ant-advanced-search-form"
        :labelCol="{span: 8}"
        :wrapperCol="{span: 16}"
        :form="form" @submit="handleSearch">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-input-group compact  style="margin-top: 6px;">
                <a-select  style="width: 110px;" v-model="selectedOption" @select="handleChange" default-value="mobileNo">
                  <a-select-option value="mobileNo">
                    手机号
                  </a-select-option>
                  <a-select-option value="name">
                    姓名
                  </a-select-option>
                  <a-select-option value="idNo">
                    身份证号
                  </a-select-option>
                  <a-select-option value="applyNo">
                    借款流水号
                  </a-select-option>
                  <a-select-option value="channelOrderNo">
                    渠道订单号
                  </a-select-option>
                </a-select>
                <a-input style="width: 61%"  @input="handleInput" :placeholder="inputPlaceholder" v-model="inputValue"/>
            </a-input-group>
          </a-col>
          <a-col :span="6">
             <a-form-model-item label="渠道方编号:" prop="channelCode">
                <a-select placeholder="请选择" v-model="form.channelCode" :allowClear=true  >
                    <a-select-option v-for="(item) in channelCodeList" :key="item">
                    {{  item }}
                  </a-select-option>
                </a-select>
             </a-form-model-item>
          </a-col>
          <a-col  :span="6">
            <a-form-model-item label="资产方编号:" prop="fundCode">
              <a-select placeholder="请选择" v-model="form.fundCode" :allowClear=true  >
                  <a-select-option v-for="(item ) in fundCodeList" :key="item">
                    {{ item }}
                  </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col  :span="6">
            <a-form-model-item label="借款状态:" prop="applyStatus">
              <a-select placeholder="请选择"   v-model="form.applyStatus" :allowClear=true >
                    <a-select-option v-for="(item) in statusList" :key="item.key">
                    {{  item.value}}
                  </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col  :span="6">
            <a-form-model-item label="借款申请时间:" prop="applyTime">
              <a-date-picker  placeholder="请选择" v-model="form.applyTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"   />
            </a-form-model-item>
          </a-col>
          <a-col :span="18" style="text-align: right;" >
              <a-button type="primary" html-type="submit" style="margin-right: 10px;">
                搜索
              </a-button>
              <a-button  html-type="submit" @click="handleReset">
                清空
              </a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <a-row class="page-content">
      <a-col :span="24">
        <a-table
          :scroll="{ x: 2500 }"
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          :row-key="record => record.id"
          @change="getPage"
        >
        <span slot="applyNo" slot-scope="text, record">
            <a @click="applyNoinfo(record, 'applyNo')">{{ text }}</a>
          </span>
          <span slot="applyStatus" slot-scope="text">{{ text ==='SUCCESS' ? '成功' :  text ==='FAIL' ? '失败' : '申请中' }}</span>
          <span slot="deviceInfo" slot-scope="text, record">
            <a @click="showInfo(record, 'deviceInfo')">查看</a>
          </span>
          <span slot="bankCardInfo" slot-scope="text, record">
            <a @click="showInfo(record, 'bankCardInfo')">查看</a>
          </span>
        </a-table>
      </a-col>
    </a-row>
    <a-modal
      v-model="detailing"
      title="查看信息"
      @ok="() => detailing = false"
      okText="关闭"
      :bodyStyle="{maxHeight: '400px', 'overflow': 'scroll'}"
      :cancelButtonProps="{style: { display: 'none' }}"
      >
        <pre>{{ detailInfo }}</pre>
    </a-modal>
  </div>
</template>

<script>
import { loanOrderListQuery } from '@/api/loan';

const columns = [
  {
    title: '借款流水号',
    dataIndex: 'applyNo',
    key: 'applyNo',
    scopedSlots: { customRender: 'applyNo' },
  },
  {
    title: '订单号',
    dataIndex: 'orderNo',
    key: 'orderNo',
  },
  {
    title: '渠道方编号',
    dataIndex: 'channelCode',
    key: 'channelCode',
  },
  {
    title: '渠道方订单号',
    dataIndex: 'channelOrderNo',
    key: 'channelOrderNo',
  },
  {
    title: '借款状态',
    dataIndex: 'applyStatus',
    key: 'applyStatus',
    scopedSlots: { customRender: 'applyStatus' },
  },
  {
    title: '失败原因',
    key: 'failReason',
    dataIndex: 'failReason',
    ellipisis: true,
  },
  {
    title: '资金方编码',
    key: 'fundCode',
    dataIndex: 'fundCode',
  },
  {
    title: '资金方订单号',
    key: 'fundOrderNo',
    dataIndex: 'fundOrderNo',
  },
  {
    title: '借款申请时间',
    key: 'applyTime',
    dataIndex: 'applyTime',
  },
  {
    title: '放款时间',
    key: 'withdrawDate',
    dataIndex: 'withdrawDate',
  },
  {
    title: '手机号',
    key: 'mobileNo',
    dataIndex: 'mobileNo',
  },
  {
    title: '身份证号',
    key: 'idNo',
    dataIndex: 'idNo',
  },
  {
    title: '姓名',
    key: 'name',
    dataIndex: 'name',
    width: 100,
  },
  {
    title: '总金额',
    key: 'totalAmountStr',
    dataIndex: 'totalAmountStr',
  },
  {
    title: '期数',
    key: 'period',
    dataIndex: 'period',
  },
  {
    title: '借款用途',
    key: 'loanUsage',
    dataIndex: 'loanUsage',
  },
  // {
  //   title: '设备信息',
  //   key: 'deviceInfo',
  //   dataIndex: 'deviceInfo',
  //   scopedSlots: { customRender: 'deviceInfo' },
  // },
  {
    title: '借款卡信息',
    key: 'bankCardInfo',
    dataIndex: 'bankCardInfo',
    scopedSlots: { customRender: 'bankCardInfo' },
  },
];

export default {
  name: 'Home',
  data() {
    return {
      channelCodeList: ['XINRENPAI'],
      fundCodeList: ['NIWODAI'],
      statusList: [{ key: 'SUCCESS', value: '成功' }, { key: 'FAIL', value: '失败' }, { key: 'APPLYING', value: '申请中' }],
      selectedOption: 'mobileNo',
      inputValue: '',
      inputPlaceholder: '请输入手机号',
      data: [],
      columns,
      expand: false,
      form: {
        mobileNo: '',
        name: '',
        idNo: '',
        applyNo: '',
        channelOrderNo: '',
        applyTime: '',
        channelCode: undefined,
        fundCode: undefined,
        applyStatus: undefined,
      },
      detailInfo: '',
      detailing: false,
      loading: false,
      pagination: {
        current: 1,
        total: 0,
      },
    };
  },
  methods: {
    handleInput(event) {
      this.inputValue = event.target.value.trim();
    },
    applyNoinfo(record) {
      this.$router.push({
        name: 'loanListinfo',
        query: { loanListinfo: record.repayPlan },
      });
    },

    handleReset() {
      this.inputValue = '';
      this.form = {
        mobileNo: '',
        name: '',
        idNo: '',
        applyNo: '',
        channelOrderNo: '',
        applyTime: '',
        channelCode: undefined,
        fundCode: undefined,
        applyStatus: undefined,
      };
      this.fetchPage();
    },
    handleChange(value) {
      this.inputValue = '';
      this.form.name = '';
      this.form.idNo = '';
      this.form.applyNo = '';
      this.form.channelOrderNo = '';
      this.form.mobileNo = '';
      this.inputPlaceholder = {
        name: '请输入姓名',
        idNo: '请输入身份证号',
        applyNo: '请输入借款流水号',
        channelOrderNo: '请输入渠道方订单号',
        mobileNo: '请输入手机号',
      }[value];
    },
    showInfo(record, type) {
      this.detailInfo = JSON.stringify(JSON.parse(record[type] || ''), null, 2);
      this.detailing = true;
    },
    handleSearch(e) {
      e.preventDefault();
      const selectedValue = this.selectedOption;
      const formKey = {
        name: 'name',
        idNo: 'idNo',
        applyNo: 'applyNo',
        channelOrderNo: 'channelOrderNo',
        mobileNo: 'mobileNo',
      }[selectedValue];
      this.form[formKey] = this.inputValue;
      console.log(this.form);
      this.pagination.current = 1;
      this.fetchPage();
    },
    async fetchPage() {
      this.loading = true;
      const { result, success, errorMsg } = await loanOrderListQuery({
        ...this.form,
        limit: 10,
        offset: this.pagination.current,
      });
      this.loading = false;
      if (!success) {
        this.$message.error(errorMsg);
        return;
      }
      this.data = result.loanOrderFlowList;
      this.pagination.total = result.total;
    },
    getPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchPage();
    },
  },
  mounted() {
    this.fetchPage();
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 30px 20px;
}

.page-content {
  background-color: #fff;
  padding: 16px 12px 0;
}
</style>
