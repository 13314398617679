<template>
  <div class="page">
    <a-row class="page-content" style="padding-bottom: 40px">
      <!-- <a-page-header
        title="返回"
        @back="() => this.$router.go(-1)"
      /> -->
      <!-- <a-col :span="24">
        <a-tabs default-active-key="1" @change="callback">
          <a-tab-pane key="1" tab="还款明细列表">
            Content of Tab Pane 1
          </a-tab-pane>
          <a-tab-pane key="2" tab="合同列表" force-render>
            Content of Tab Pane 2
          </a-tab-pane>
        </a-tabs>
      </a-col> -->
      <a-col :span="24">
        <a-table
          :scroll="{ x: 1800 }"
          :columns="columns"
          :data-source="data"
          :pagination="false"
          :row-key="record => record.periodNo"
        >
        <span slot="status" slot-scope="text">{{ text ==='not_pay' ? '未还款' :  text ==='overdue' ? '已逾期' : text ==='payoff' ? '已结清' : '' }}</span>
        <span slot="agreedRepayDate" slot-scope="text">{{  text | formatDate  }}</span>
        <span slot="principal" slot-scope="text">{{  text | currency   }}</span>
        <span slot="alreadyPrincipal" slot-scope="text">{{  text | currency   }}</span>
        <span slot="interest" slot-scope="text">{{  text | currency   }}</span>
        <span slot="alreadyInterest" slot-scope="text">{{  text | currency   }}</span>
        <span slot="penalty" slot-scope="text">{{  text | currency   }}</span>
        <span slot="alreadyPenalty" slot-scope="text">{{  text | currency   }}</span>
        <span slot="feeAmount" slot-scope="text">{{  text | currency   }}</span>
        <span slot="alreadyFeeAmount" slot-scope="text">{{  text | currency   }}</span>
        <span slot="TotalAmountPayable" slot-scope="text">{{  text | currency   }}</span>
        <span slot="TotalPaidAmount" slot-scope="text">{{  text | currency   }}</span>
        </a-table>

      </a-col>
    </a-row>

  </div>
</template>

<script>
const columns = [
  {
    title: '当前期次',
    dataIndex: 'periodNo',
    key: 'periodNo',
    width: 100,
    fixed: 'left',
  },
  {
    title: '期次状态',
    dataIndex: 'status',
    key: 'status',

    scopedSlots: { customRender: 'status' },
  },
  {
    title: '应还时间',
    dataIndex: 'agreedRepayDate',
    key: 'agreedRepayDate',

    scopedSlots: { customRender: 'agreedRepayDate' },
  },
  {
    title: '应还本金',
    dataIndex: 'principal',
    key: 'principal',

    scopedSlots: { customRender: 'principal' },
  },
  {
    title: '实还本金',
    dataIndex: 'alreadyPrincipal',
    key: 'alreadyPrincipal',

    scopedSlots: { customRender: 'alreadyPrincipal' },
  },
  {
    title: '应还利息',
    key: 'interest',
    dataIndex: 'interest',

    scopedSlots: { customRender: 'interest' },

  },
  {
    title: '实还利息',
    key: 'alreadyInterest',
    dataIndex: 'alreadyInterest',
    scopedSlots: { customRender: 'alreadyInterest' },

  },
  {
    title: '应还罚息',
    key: 'penalty',
    dataIndex: 'penalty',
    scopedSlots: { customRender: 'penalty' },
  },
  {
    title: '实还罚息',
    key: 'alreadyPenalty',
    dataIndex: 'alreadyPenalty',
    scopedSlots: { customRender: 'alreadyPenalty' },

  },
  {
    title: '应还服务费',
    key: 'feeAmount',
    dataIndex: 'feeAmount',
    scopedSlots: { customRender: 'feeAmount' },

  },
  {
    title: '实还服务费',
    key: 'alreadyFeeAmount',
    dataIndex: 'alreadyFeeAmount',
    scopedSlots: { customRender: 'alreadyFeeAmount' },

  },
  {
    title: '应还总额',
    key: 'TotalAmountPayable',
    dataIndex: 'TotalAmountPayable',
    fixed: 'right',
    width: 120,
    scopedSlots: { customRender: 'TotalAmountPayable' },
  },
  {
    title: '实还总额',
    key: 'TotalPaidAmount',
    dataIndex: 'TotalPaidAmount',
    fixed: 'right',
    width: 120,
    scopedSlots: { customRender: 'TotalPaidAmount' },
  },

];
export default {
  name: 'Home',
  data() {
    return {
      data: [],
      columns,
    };
  },
  filters: {
    formatDate(timestamp) {
      if (timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      // const hours = String(date.getHours()).padStart(2, '0');
      // const minutes = String(date.getMinutes()).padStart(2, '0');
      // const seconds = String(date.getSeconds()).padStart(2, '0');
      // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }
      return '';
    },
    currency(value) {
      const formattedNumber = (value / 100).toLocaleString('zh-CN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      return formattedNumber;
    },
  },
  methods: {
    /**
     * 应还总额/实还总额计算
     * @param {接口返回数组} array
     */
    addSumAndDifferenceToObjects(array) {
      for (let i = 0; i < array.length; i++) {
        // 应还总额
        const sum = array[i].breachEarlySettle + array[i].feeAmount + array[i].interest + array[i].penalty + array[i].principal;
        // 实还总额
        const sum1 = array[i].alreadyBreachEarlySettle + array[i].alreadyFeeAmount + array[i].alreadyInterest + array[i].alreadyPenalty + array[i].alreadyPrincipal;
        array[i].TotalAmountPayable = sum;
        array[i].TotalPaidAmount = sum1;
      }
      return array;
    },
    /**
    * 总计数据计算
    * @param {原数据} data
    */
    sumValuesAndIds(data) {
      return data.reduce((acc, obj) => {
        acc.TotalPaidAmount += obj.TotalPaidAmount || 0;
        acc.alreadyFeeAmount += obj.alreadyFeeAmount || 0;
        acc.alreadyInterest += obj.alreadyInterest || 0;
        acc.alreadyPenalty += obj.alreadyPenalty || 0;
        acc.alreadyPrincipal += obj.alreadyPrincipal || 0;
        acc.TotalAmountPayable += obj.TotalAmountPayable || 0;
        acc.feeAmount += obj.feeAmount || 0;
        acc.interest += obj.interest || 0;
        acc.penalty += obj.penalty || 0;
        acc.principal += obj.principal || 0;
        return acc;
      }, {
        TotalPaidAmount: 0,
        alreadyFeeAmount: 0,
        alreadyInterest: 0,
        alreadyPenalty: 0,
        alreadyPrincipal: 0,
        TotalAmountPayable: 0,
        feeAmount: 0,
        interest: 0,
        penalty: 0,
        principal: 0,
      });
    },
  },
  mounted() {
    try {
      const jsonString = this.$route.query.loanListinfo;
      if (typeof jsonString !== 'undefined') {
        const result = JSON.parse(jsonString);
        this.data = this.addSumAndDifferenceToObjects(result);
        const {
          TotalPaidAmount, alreadyFeeAmount, alreadyInterest, alreadyPenalty, alreadyPrincipal, TotalAmountPayable, feeAmount, interest, penalty, principal,
        } = this.sumValuesAndIds(this.data);
        const newobj = {
          TotalPaidAmount,
          alreadyFeeAmount,
          alreadyInterest,
          alreadyPenalty,
          alreadyPrincipal,
          TotalAmountPayable,
          feeAmount,
          interest,
          penalty,
          principal,
          agreedRepayDate: '',
          periodNo: '合计',
          status: '',
        };
        this.data.push(newobj);
      } else {
        console.error('只有借款成功才有还款明细数据哦');
      }
    } catch (error) {
      console.error('Invalid JSON:', error);
    }
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 30px 20px;
}

.page-content {
  background-color: #fff;
  padding: 16px 12px 0;
}
</style>
